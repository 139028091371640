import React, { useEffect, useState } from 'react';
import { Button, TextField, Container, Typography, Alert, Box } from '@mui/material';
import { useUser } from '../components/userContext';
import { useNavigate, useParams } from 'react-router-dom';

const InstitutionPage = () => {
    const { user } = useUser();
    const [formData, setFormData] = useState({
        institution_id: '',
        institution: '',
        street: '',
        houseNumber: '',
        postalCode: '',
        city: '',
        station: '',
        phone: '',
    });

    const [serverError, setServerError] = useState({});
    const [errors, setErrors] = useState({});
    const { institution_id } = useParams(); // institution aus der URL holen. institution zum Bearbeiten
    // eslint-disable-next-line
    const [isEditMode, setIsEditMode] = useState(institution_id != null);
    const navigate = useNavigate();


    useEffect(() => {
        if (institution_id) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URI}/api/institutions/${institution_id}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': user.api_key,
                        },
                    });
                    if (response.ok) {
                        const data = await response.json();
                        const streetParts = data.street_and_number.split(' ');
                        const phouseNumber = streetParts.pop();
                        const street = streetParts.join(' ');

                        setFormData({
                            institution_id: data.institution_id || '',
                            institution: data.object_institution || '',
                            street: street || '',
                            houseNumber: phouseNumber || '',
                            postalCode: data.postal_code || '',
                            city: data.city || '',
                            station: data.station || '',
                            phone: data.phone_number || '',
                        });
                    } else {
                        const errorData = await response.json();
                        throw new Error(errorData.message);
                    }
                } catch (error) {
                    console.error('Fehler beim Abrufen der Institution:', error.message);
                    setServerError(error.message);
                }
            };

            fetchData();
        }
        // eslint-disable-next-line
    }, [institution_id]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log(formData);

        let newErrors = {};
        let newServerErrors = {};

        // Validierung für die Felder Einrichtung, Straße, Hausnummer, PLZ, Ort und Telefon
        if (!formData.institution) newErrors.institution = 'Einrichtung ist erforderlich';
        if (!formData.street) newErrors.street = 'Straße ist erforderlich';
        if (!formData.houseNumber) newErrors.houseNumber = 'Hausnummer ist erforderlich';
        if (!formData.postalCode) newErrors.postalCode = 'PLZ ist erforderlich';
        if (!formData.city) newErrors.city = 'Ort ist erforderlich';
        if (!formData.phone) newErrors.phone = 'Telefon ist erforderlich';
        if (!formData.station) newErrors.station = 'Station ist erforderlich';


        setErrors(newErrors);
        setServerError(newServerErrors);

        console.log(newErrors);

        // Überprüfen, ob das newErrors-Objekt leer ist
        const isValid = Object.keys(newErrors).length === 0;

        if (isValid) {
            if (isEditMode) {
                await updateInstitution();
            } else {
                await createInstitution();
            }
        }
    };

    const createInstitution = async () => {
        const url = process.env.REACT_APP_API_URI + '/api/institutions';
        const instdata = {
            object_institution: formData.institution,
            street_and_number: formData.street + ' ' + formData.houseNumber,
            postal_code: formData.postalCode,
            city: formData.city,
            station: formData.station,
            phone_number: formData.phone
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user.api_key,
                },
                body: JSON.stringify(instdata),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }

            const data = await response.json();
            console.log('Neue Institution erstellt:', data);

            navigate(-1);

        } catch (error) {
            console.error('Fehler beim Erstellen der Institution:', error.message);
            setServerError(error.message);
        }
    };

    const updateInstitution = async () => {
        const url = process.env.REACT_APP_API_URI + '/api/institutions/' + formData.institution_id;
        const instdata = {
            object_institution: formData.institution,
            street_and_number: formData.street + ' ' + formData.houseNumber,
            postal_code: formData.postalCode,
            city: formData.city,
            station: formData.station,
            phone_number: formData.phone
        };

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.api_key,
                },
                body: JSON.stringify(instdata),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }

            const data = await response.json();
            console.log('Neue Institution erstellt:', data);

            navigate(-1);

        } catch (error) {
            console.error('Fehler beim Erstellen der Institution:', error.message);
            setServerError(error.message);
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <Container
            maxWidth="sm"
            mt={2}
            mb={2}
            style={{
                background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)',
                borderRadius: '10px',
                border: '1px solid #F5F5F5',
                margin: '0 auto', // Center the box horizontally
            }}
        >
            <Box p={4}>
                <Typography variant="h5" component="h1" gutterBottom textAlign="center">
                    Institution anlegen/bearbeiten
                </Typography>
                {errors && Object.values(errors).join(', ') && <Alert severity="error" sx={{ my: 2 }}>{Object.values(errors).join(', ')}</Alert>}
                {typeof serverError === 'string' && serverError && <Alert severity="error" sx={{ my: 2 }}>{serverError}</Alert>}
                <Box component="form" onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Einrichtung"
                        name="institution"
                        value={formData.institution}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.institution}
                        helperText={errors.institution}
                    />
                    <TextField
                        fullWidth
                        label="Straße"
                        name="street"
                        value={formData.street}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.street}
                        helperText={errors.street}
                    />
                    <TextField
                        fullWidth
                        label="Hausnummer"
                        name="houseNumber"
                        value={formData.houseNumber}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.houseNumber}
                        helperText={errors.houseNumber}
                    />
                    <TextField
                        fullWidth
                        label="PLZ"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.postalCode}
                        helperText={errors.postalCode}
                    />
                    <TextField
                        fullWidth
                        label="Ort"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.city}
                        helperText={errors.city}
                    />
                    <TextField
                        fullWidth
                        label="Station"
                        name="station"
                        value={formData.station}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.station}
                        helperText={errors.station}
                    />
                    <TextField
                        fullWidth
                        label="Telefonnummer"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.phone}
                        helperText={errors.phone}
                    />
                    <Box sx={{ mt: 2 }}>
                        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
                            {isEditMode ? 'Änderungen speichern' : 'Speichern'}
                        </Button>
                        <Button variant="outlined" color="secondary" style={{ marginTop: '16px', marginLeft: '12px' }} onClick={handleCancel}>
                            Abbrechen
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default InstitutionPage;
