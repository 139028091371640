import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Alert, Box, Link, Stack, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isPasswordValid } from '../misc/helperFunctions';

const RegistrationPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        institution: '',
        street: '',
        houseNumber: '',
        postalCode: '',
        city: '',
        station: '',
        phone: '',
    });

    const navigate = useNavigate();

    const [serverError, setServerError] = useState({});
    const [errors, setErrors] = useState({});


    const handleChange = (event) => {
        const { name, value } = event.target;

        const lowercaseValue = name === 'email' ? value.toLowerCase() : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: lowercaseValue,
        }));

    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let newErrors = {};
        let newServerErrors = {};

        if (!validateEmail(formData.email)) {
            newErrors.email = "Ungültige E-Mail-Adresse";
        }

        // Überprüfen, ob die Passwortfelder ausgefüllt sind
        if (!formData.password) newErrors.password = 'Passwort ist erforderlich';
        else if (!isPasswordValid(formData.password)) {
            newErrors.password = 'Passwort muss mindestens 6 Zeichen lang sein, einen Großbuchstaben und ein Sonderzeichen enthalten';
        }

        if (!formData.confirmPassword) newErrors.confirmPassword = 'Passwortbestätigung ist erforderlich';

        // Überprüfen, ob die Passwörter übereinstimmen
        if (formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwörter stimmen nicht überein';
        }

        // Validierung für die Felder Einrichtung, Straße, Hausnummer, PLZ, Ort und Telefon
        if (!formData.institution) newErrors.institution = 'Einrichtung ist erforderlich';
        if (!formData.street) newErrors.street = 'Straße ist erforderlich';
        if (!formData.houseNumber) newErrors.houseNumber = 'Hausnummer ist erforderlich';
        if (!formData.postalCode) newErrors.postalCode = 'PLZ ist erforderlich';
        if (!formData.city) newErrors.city = 'Ort ist erforderlich';
        if (!formData.phone) newErrors.phone = 'Telefon ist erforderlich';
        if (!formData.station) newErrors.station = 'Station ist erforderlich';


        setErrors(newErrors);
        setServerError(newServerErrors);

        const isValid = Object.keys(newErrors).length === 0;

        if (isValid) {
            const userExists = await checkUserExists(formData.email);
            if (userExists === true) {
                setServerError('Benutzer mit dieser E-Mail-Adresse existiert bereits.');
            } else if (userExists === false) {
                await createInstitution();
            }
        }
    };

    const checkUserExists = async (username) => {
        const url = process.env.REACT_APP_API_URI + `/api/users/${username}`;
        try {
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                if (data) {
                    return true; // Benutzer existiert
                }
            } else if (response.status === 404) {
                return false; // Benutzer nicht gefunden
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }
        } catch (error) {
            console.error('Fehler beim Überprüfen des Benutzers:', error.message);
            setServerError(error.message);
            return null; // Fehlerzustand
        }
    };



    const createUser = async (institutionId) => {
        const userUrl = process.env.REACT_APP_API_URI + '/api/users'; // Der Endpunkt der API-Route für Benutzer
        const userData = {
            username: formData.email,
            institution_id: institutionId,
            registration_status: 'in Bearbeitung',
            password: formData.password,
            account_activated: false,
            role: 'user', // Standardrolle als 'user' festlegen
        };

        try {
            const userResponse = await fetch(userUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            if (!userResponse.ok) {
                const userErrorData = await userResponse.json();
                throw new Error(userErrorData.message);
            }

            const userResult = await userResponse.json();
            console.log('Neuer Benutzer erstellt:', userResult);

            // Nach erfolgreicher Registrierung:
            navigate('/confirmation');

        } catch (error) {
            console.error('Fehler beim Erstellen des Benutzers:', error.message);
            setServerError(error.message);
        }

    };

    const createInstitution = async () => {
        const url = process.env.REACT_APP_API_URI + '/api/institutions';
        const instdata = {
            object_institution: formData.institution,
            street_and_number: formData.street + ' ' + formData.houseNumber,
            postal_code: formData.postalCode,
            city: formData.city,
            station: formData.station,
            phone_number: formData.phone
        };

        // TODO: user.api_key muss zwingend in der auth übergeben werden,
        // um die institution anzulegen.
        // Problem: bei der registrierung gibt es den user noch nicht
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(instdata),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }

            const data = await response.json();
            console.log('Neue Institution erstellt:', data);

            // Nachdem die Institution erfolgreich erstellt wurde, legen Sie den Benutzer an
            createUser(data.institution_id); // Angenommen, dass data.institution_id die ID der neu erstellten Institution enthält

        } catch (error) {
            console.error('Fehler beim Erstellen der Institution:', error.message);
            setServerError(error.message);
        }
    };


    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };


    return (
        <Container
            maxWidth="sm"
            mt={2}
            mb={2}
        >
            <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px', background: '#f7f7f7' }}>
                <Typography variant="h6" align="center" color="#E46450" sx={{ fontWeight: 'bold' }}>Willkommen bei KTW-Anmeldung.de!</Typography>
                <Typography variant="body1" align="center" sx={{ marginTop: 2 }}>
                    Hier einige wichtige Informationen für Sie:
                </Typography>
                <br />
                <Box component="ul" sx={{ paddingInlineStart: '20px', maxWidth: '80%', margin: '0 auto', listStyleType: 'disc' }}>
                    <Typography variant="body1">
                        <li>Unser regulärer Fahrdienst steht Ihnen von <strong>07:00 bis 17:00 Uhr</strong> zur Verfügung.</li>
                        <br />
                        <li>Bitte beachten Sie, dass unser Service ausschließlich für Patienten mit einem <strong>Gewicht unter 150 Kg</strong> vorgesehen ist.</li>
                        <br />
                        <li>Sie haben die Möglichkeit, Ihre eigenen Bestellungen <strong>online</strong> anzulegen und zu verwalten.</li>
                    </Typography>
                </Box>
                <Typography variant="body1" align="center" sx={{ marginTop: 2, fontStyle: 'italic' }}>
                    Registrieren Sie sich in dem unten stehenden Formular.<br /> Nachdem Sie die Registrierung durchgeführt haben, <br />wird diese von der Leitstelle geprüft und freigegeben.
                </Typography>
            </Paper>
            <Box
                mt={4}
                p={4}
                style={{
                    background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)',
                    borderRadius: '10px',
                    border: '1px solid #F5F5F5',
                }}
            >
                <Typography variant="h6" gutterBottom textAlign="center">
                    Registrierung...
                </Typography>
                {errors && Object.values(errors).join(', ') && <Alert severity="error" sx={{ my: 2 }}>{Object.values(errors).join(', ')}</Alert>}
                {typeof serverError === 'string' && serverError && <Alert severity="error" sx={{ my: 2 }}>{serverError}</Alert>}
                <Box component="form" onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Benutzername (Mailadresse)"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                    <TextField
                        fullWidth
                        label="Passwort"
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.password}
                        helperText={errors.password}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Passwort bestätigen"
                        name="confirmPassword"
                        type="password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Einrichtung (Name der Klinik)"
                        name="institution"
                        value={formData.institution}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.institution}
                        helperText={errors.institution}
                    />
                    <TextField
                        fullWidth
                        label="Straße"
                        name="street"
                        value={formData.street}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.street}
                        helperText={errors.street}
                    />
                    <TextField
                        fullWidth
                        label="Hausnummer"
                        name="houseNumber"
                        value={formData.houseNumber}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.houseNumber}
                        helperText={errors.houseNumber}
                    />
                    <TextField
                        fullWidth
                        label="PLZ"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.postalCode}
                        helperText={errors.postalCode}
                    />
                    <TextField
                        fullWidth
                        label="Ort"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.city}
                        helperText={errors.city}
                    />
                    <TextField
                        fullWidth
                        label="Station"
                        name="station"
                        value={formData.station}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.station}
                        helperText={errors.station}
                    />
                    <TextField
                        fullWidth
                        label="Telefon"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.phone}
                        helperText={errors.phone}
                    />
                    <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
                        Registrieren
                    </Button>
                    <Stack direction="row" sx={{ mt: 4 }} alignItems="center" justifyContent="center">
                        <Typography variant="body2">Haben Sie schon einen Zugang?.</Typography>
                        <Link ml={1} variant="body2" href="/login">Zur Anmeldung</Link>
                    </Stack>
                </Box>
            </Box>
        </Container>
    );
};

export default RegistrationPage;
