import React from 'react';
import { Box } from '@mui/material';

const TransportDesignBox = ({ children, isExpertMode, minHeight, ...props }) => {
    return (
        <Box
            mt={isExpertMode ? 0 : 4}
            mb={2}
            pt={isExpertMode ? 2 : 12}
            pl={isExpertMode ? 2 : 4}
            pr={isExpertMode ? 2 : 4}
            pb={isExpertMode ? 2 : 4}
            style={{
                background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)',
                borderRadius: '10px',
                width: isExpertMode ? 'auto' : '100%',
                border: '1px solid #F5F5F5',
                position: 'relative',
                zIndex: 1,
            }}
            minHeight={minHeight}
            {...props}
        >
            {children}
        </Box>
    );
};

export default TransportDesignBox;