import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';

const HouseNumberAutocomplete = ({ city, street, value, onChange, isExpertMode, ...otherProps }) => {
    const [houseNumbers, setHouseNumbers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const loadHouseNumbers = async () => {
            if (city && street) {
                setLoading(true);
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URI}/api/get_house_numbers?location=${city}&street=${street}`);
                    const data = await response.json();
                    setHouseNumbers(data); // Hausnummern setzen
                } catch (error) {
                    console.error('Fehler beim Laden der Hausnummern:', error);
                    setHouseNumbers([]); // Fehlerfall: leere Liste
                } finally {
                    setLoading(false);
                }
            } else {
                setHouseNumbers([]); // Zurücksetzen, falls Ort oder Straße fehlt
            }
        };

        loadHouseNumbers();
    }, [city, street]); // Lädt Hausnummern erneut bei Änderung von Ort oder Straße

    return (
        <Autocomplete
            options={houseNumbers}
            value={value || ''}
            loading={loading}
            freeSolo // Erlaubt benutzerdefinierte Eingaben
            fullWidth
            style={{ flex: '1 1 auto' }}
            onChange={(event, newValue) => onChange(newValue)}
            onInputChange={(event, newValue) => {
                // Verarbeitet die Eingabe des Benutzers direkt in `onChange`, um Aktualisierungen zu unterstützen
                if (event && event.type === 'change') {
                    onChange(newValue);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...otherProps}
                    label="Hausnummer *"
                    margin="normal"
                    error={!value}
                    helperText={!value && (isExpertMode ? '' : 'Hausnummer ist erforderlich')}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default HouseNumberAutocomplete;