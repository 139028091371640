import React, { useState } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { useUser } from './userContext';

const AddressSearch = ({ onAddressSelect, variant = "outlined" }) => {
    const { user } = useUser(); // Angemeldeter User
    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');

    // Funktion zum Abrufen von Adressvorschlägen
    const fetchSuggestions = async (searchTerm) => {
        setLoading(true);
        try {
            if (searchTerm && searchTerm.length > 0) {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/search_address?query=${encodeURIComponent(searchTerm)}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': user.api_key,
                    },
                });
                const data = await response.json();

                console.log("API-Ergebnisse:", data);  //

                if (Array.isArray(data)) {
                    setOptions(data);
                } else {
                    setOptions([]); // Leere Liste, falls keine Ergebnisse
                }
            } else {
                setOptions([]); // Leere Liste, falls Eingabefeld leer
            }
        } catch (error) {
            console.error('Fehler beim Abrufen der Adressvorschläge:', error);
            setOptions([]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Autocomplete
            open={open}
            onOpen={() => {
                setOpen(true);
                if (inputValue) {
                    fetchSuggestions(inputValue);
                }
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event, value) => {
                onAddressSelect(value);
                setInputValue(''); // Eingabefeld zurücksetzen
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                fetchSuggestions(newInputValue);
            }}
            options={options}
            filterOptions={(x) => x} // Deaktiviert die Standard-Filterung, sodass alle Optionen angezeigt werden
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => {
                // Prüfen, ob Strasse und Ort vorhanden sind, sonst leeren String zurückgeben
                return option.ort && option.strasse ? `${option.ort}, ${option.strasse}` : 'Unbekannte Adresse';
            }}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                        <div>
                            {option.plz ? option.plz : '???'} {option.ort ? option.ort : 'Unbekannter Ort'}, {option.strasse || 'Unbekannte Strasse'} {option.hausnummer && ` ${option.hausnummer}`}
                        </div>
                    </li>
                );
            }}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Ort + Straße # Hausnummer, * für global, ? für Synonyme"
                    placeholder="Ort + Straße # Hausnummer, * für global, ? für Synonyme"
                    variant={variant}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default AddressSearch;